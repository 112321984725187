@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; 
      scrollbar-width: none; 
    }
    
    ol, ul, menu {
      list-style: inherit;
      margin: 0;
      padding: 0;
    }
  }
}
