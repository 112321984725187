$primary-light: #ff4e4e;
$primary-dark: #ff4e4e;

$secondary-green: #67ce67;
$secondary-yellow: #ffb619;

$neutral-white: #f1f1f1;
$neutral-black: #000000;

$transperancy-100-dark: #252525;
$transperancy-50-dark: #151517;

$transperancy-100-light: #e9ecef;
$transperancy-50-light: #dee2e6;

$border-error-dark: #ff4e4e;
$border-default-dark: #aaaaaa;
$border-disable-dark: #aaaaaa;

$border-error-light: #ff4e4e;
$border-default-light: #aaaaaa;
$border-disable-light: #aaaaaa;
