@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./colors.scss";
@import "./fonts.scss";

.body-dark {
  background-color: $neutral-black;
  color: $neutral-white;

  .text-white {
    color: $neutral-white !important;
  }

  .text-black {
    color: $neutral-black !important;
  }

  .bg-primary {
    background-color: $primary-dark!important;
  }

  .bg-transperancy-100 {
    background-color: $transperancy-100-dark;
  }

  .bg-transperancy-50 {
    background-color: $transperancy-50-dark;
  }

  .bg-secondary-green {
    background-color: $secondary-green;
  }

  .bg-secondary-yellow {
    background-color: $secondary-yellow;
  }

  .bg-neutral-white {
    background-color: $neutral-white;
  }

  .bg-neutral-black {
    background-color: $neutral-black;
  }

  .text-primary {
    color: $primary-dark;
  }

  .border-error {
    border-color: $border-error-dark;
  }

  .border-default {
    border-color: $border-default-dark;
  }

  .border-disable {
    border-color: $border-disable-dark;
  }
  .datepicker {
    width: 100%;
  }
  .button.btn {
    color: $neutral-white !important
  }
}

.body-white {
  background-color: $neutral-white;
  color: $neutral-black;

  .text-white {
    color: $neutral-black !important;
  }

  .text-black {
    color: $neutral-white !important;
  }

  .bg-black {
    background-color: $neutral-white !important;
  }

  .bg-primary {
    background-color: $primary-light!important;
  }

  .bg-transperancy-100 {
    background-color: $transperancy-100-light;
  }

  .bg-transperancy-50 {
    background-color: $transperancy-50-light;
  }

  .bg-secondary-green {
    background-color: $secondary-green;
  }

  .bg-secondary-yellow {
    background-color: $secondary-yellow;
  }

  .bg-neutral-white {
    background-color: $neutral-black;
  }

  .bg-neutral-black {
    background-color: $neutral-white;
  }

  .text-primary {
    color: $primary-light;
  }

  .border-error {
    border-color: $border-error-light;
  }

  .border-default {
    border-color: $border-default-light;
  }

  .border-disable {
    border-color: $border-disable-light;
  }
  .datepicker {
    width: 100%;
  }
  .button.btn {
    color: $neutral-white !important
  }
}

